<template>
  <div class="user-add">
    <div class="header-title">
      <i class="el-icon-back bach" @click="previous" />
      <span>创建主用户</span>
    </div>
    <div>
      <div class="next-form-item-label">
        <label>用户账号信息</label>
      </div>
      <el-form
        ref="ruleForm"
        :inline="true"
        :model="form"
        :rules="rules"
        class="demo-form-inline"
        label-position="top"
      >
        <el-form-item label="登录名称" prop="name">
          <el-input v-model="form.name" style="width: 400px" />
        </el-form-item>
        <el-form-item label="显示名称" style="width: 300px">
          <el-input v-model="form.display_name" />
        </el-form-item>
        <el-form-item label="别名">
          <el-input v-model="form.alias" />
        </el-form-item>
        <el-form-item label="父级主账号" prop="display_name">
          <el-select
            v-model="form.parent_domain_id"
            clearable
            placeholder=""
            style="width: 100%"
          >
            <el-option
              v-for="item in parentAccountList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-row>
          <el-col :span="24">
            <el-form-item
              label="密码"
              style="width: 300px"
              :rules="form.radio == 2 ? rules.password : []"
              prop="password"
            >
              <el-radio-group v-model="form.radio">
                <el-radio :label="1">自动生成密码</el-radio>
                <el-radio :label="2">自定义密码</el-radio>
                <el-input
                  v-if="form.radio === 2"
                  v-model="form.password"
                  show-password
                  style="width: 300px"
                />
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="备注">
              <el-input
                v-model="form.remark"
                :autosize="{ minRows: 4, maxRows: 4 }"
                style="width: 500px"
                type="textarea"
                :rows="2"
                placeholder=""
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <div class="btn-con" style="float: left">
            <el-button type="primary" @click="submitForm('ruleForm')"
            >确定</el-button
            >
            <el-button @click="previous">取消</el-button>
          </div>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import { domain_list, main_user, all_main_user_list } from '@/api'

export default {
  data () {
    return {
      domain_list1: [],
      form: { radio: 1 },
      parentAccountList: [],
      rules: {
        name: [{ required: true, message: '请输入登录名称', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      }
    }
  },
  async created () {
    this.get_domain_list()
    const { data } = await all_main_user_list()
    this.parentAccountList = data
  },
  methods: {
    get_domain_list () {
      domain_list().then((res) => {
        console.log(res, '22')
        this.domain_list1 = res.data
      })
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const params = {
            name: this.form.name,
            phone: '', // 手机号
            display_name: this.form.display_name,
            parent_domain_id: this.form.parent_domain_id,
            password: this.form.password,
            remark: this.form.remark,
            alias: this.form.alias
          }
          main_user(params).then((res) => {
            if (res.code === 0) {
              this.$message({
                showClose: true,
                message: 'success',
                type: 'success'
              })
              if (this.form.radio === 1) {
                alert('您的密码是：' + res.password)
              }
              this.$router.push('/main')
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    previous () {
      this.$router.push('/main')
      this.$refs['ruleForm'].resetFields()
    }
  }
}
</script>

<style lang="less">
.user-add {
  .el-radio-group .el-radio {
    display: block;
    margin-bottom: 20px;
  }
}
</style>
